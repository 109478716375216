<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 200px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Plannings</span>
        </div>
      </div>
      <!-- ... -->
      <div class="z-10">
        <div class="planning-content p-8 pb-8">
          <div class="grid sm:grid-cols-2 sm:justify-end sm:space-x-2 space-y-2 sm:space-y-0">
            <div class="form-filter relative">
              <v-date-picker
                v-model="dateRange"
                is-range
                :model-config="modelConfig"
                :input-debounce="500"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="sm:flex sm:space-x-2 space-y-2 sm:space-y-0">
                    <div class="relative">
                      <input
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                        class="input h-9 xs:w-full"
                        placeholder="Start date"
                      />
                      <div
                        class="absolute right-0 inset-y-0 flex items-center cursor-pointer"
                        v-if="inputValue.start"
                        @click="clearRange"
                      >
                        <IconClear class="mr-3.5" />
                      </div>
                    </div>

                    <div class="relative">
                      <input
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                        class="input h-9 xs:w-full"
                        placeholder="End date"
                      />
                      <div
                        class="absolute right-0 inset-y-0 flex items-center cursor-pointer"
                        v-if="inputValue.end"
                        @click="clearRange"
                      >
                        <IconClear class="mr-3.5" />
                      </div>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="form-filter relative">
              <treeselect
                ref="plan-filter"
                class="h-9"
                :multiple="false"
                :options="filterOptions"
                v-model="filter"
                placeholder="Filter"
              />
            </div>
          </div>

          <div class="grid sm:grid-cols-3 sm:gap-4 mt-7" v-if="reqLoading">
            <div class="ticket-card relative animate-pulse" v-for="t in 9" :key="t">
              <div class="flex">
                <div class="mr-3">
                  <div class="h-9 bg-hline rounded-lg w-24"></div>
                </div>
              </div>

              <div class="flex mt-2">
                <div class="mr-2">
                  <div class="h-4 w-4 bg-hline rounded-lg"></div>
                </div>

                <div class="flex-auto w-full">
                  <div class="h-4 bg-hline rounded-lg"></div>
                </div>
              </div>
              <div class="flex mt-1">
                <div class="mr-2">
                  <div class="h-4 w-4 bg-hline rounded-lg"></div>
                </div>

                <div class="flex-auto w-full">
                  <div class="h-4 bg-hline rounded-lg"></div>
                </div>
              </div>
              <div class="flex mt-1 mb-1">
                <div class="mr-2">
                  <div class="h-4 w-4 bg-hline rounded-lg"></div>
                </div>

                <div class="flex-auto w-full">
                  <div class="h-4 bg-hline rounded-lg"></div>
                </div>
              </div>

              <div class="flex mb-8">
                <div class="mr-2">
                  <div class="h-4 w-4 bg-hline rounded-lg"></div>
                </div>

                <div class="flex-auto w-full">
                  <div class="h-4 bg-hline rounded-lg"></div>
                </div>
              </div>

              <div class="absolute bottom-3.5 right-4 text-right">
                <div class="text-xs font-bold leading-1 uppercase">
                  <div class="h-5 bg-hline rounded-lg w-24"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid sm:grid-cols-3 gap-4 mt-7" v-else>
            <div class="my-36 mx-auto col-span-3" v-if="!plannings.length">
              <img :src="NoPlanning" class="mx-auto" />
            </div>

            <div
              class="ticket-card relative"
              :class="p.ticket && p.ticket.data ? 'cursor-pointer hover:bg-hgray6' : ''"
              v-for="p in plannings"
              :key="p.id"
              @click="toTicket(p.ticket)"
            >
              <div
                class="absolute top-5 left-0 h-9 pt-1.5 text-white"
                :class="{
                  'bg-hplan': p.status == 'plan',
                  'bg-hpass': p.status == 'pass',
                  'bg-hfail': p.status == 'fail'
                }"
                style="width: 165px; border-radius: 0px 18px 18px 0px"
              >
                <div class="flex">
                  <div class="bg-white h-3.7 w-3.7 mt-1 ml-3.5" style="border-radius: 100%"></div>
                  <p class="text-center font-semibold ml-5 capitalize">{{ p.status }}</p>
                </div>
              </div>
              <div class="flex flex-col" style="min-height: 250px">
                <div class="mt-13">
                  <div class="flex">
                    <SvgPic class="w-3 h-3 mr-2 mt-0.1" />
                    <p class="text-xs font-medium">PIC</p>
                  </div>
                  <p class="text-sm font-semibold ml-5">{{ p.staf ? p.staf.data.name : '-' }}</p>
                </div>

                <div class="flex-grow">
                  <div class="mt-1.5">
                    <div class="flex">
                      <SvgInstrumentXs class="w-3 h-3 mr-2 mt-0.1" />
                      <p class="text-xs font-medium">Instrument</p>
                    </div>
                    <p class="text-sm font-semibold ml-5">
                      {{
                        p.instrument && p.instrument.data
                          ? `${p.instrument.data.instrument_type} - ${p.instrument.data.serial_number}`
                          : '-'
                      }}
                    </p>
                  </div>

                  <div class="mt-1.5 mb-3">
                    <div class="flex">
                      <SvgPhone class="w-3 h-3 mr-2 mt-0.1" />
                      <p class="text-xs font-medium">Phone</p>
                    </div>
                    <p class="text-sm font-semibold ml-5">
                      {{ p.staf ? p.staf.data.phone_number : '-' }}
                    </p>
                  </div>
                </div>
                <div class="w-full">
                  <div class="flex justify-end">
                    <span class="text-xl font-bold text-htext">{{ p.request_is }}</span>
                    <IconDate class="w-5.4 h-5.4 ml-2" />
                  </div>
                  <div class="flex justify-end text-13px font-medium text-htext">
                    <span>{{ p.date_is | moment('MMMM YYYY') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex justify-between space-x-2 mt-8 mx-auto text-center"
            style="max-width: 100px"
          >
            <button
              class="btn font-semibold bg-hteal1 hover:bg-hteal1 rounded-full btn-sm border-0"
              :disabled="currentPage == 1"
              @click="pageDecrease"
            >
              «
            </button>
            <button
              class="btn font-semibold rounded-full btn-sm btn-ghost no-animation hover:border-transparent"
            >
              {{ currentPage }}
            </button>
            <button
              class="btn font-semibold bg-hteal1 hover:bg-hteal1 rounded-full btn-sm border-0"
              :disabled="plannings.length <= 8"
              @click="pageIncrease"
            >
              »
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClear from '@/assets/icons/icon-close.svg?inline'
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import IconDate from '@/assets/icons/icon-date.svg?inline'
import SvgInstrumentXs from '@/assets/icons/icon-instrument.svg?inline'
import SvgPhone from '@/assets/icons/icon-phone.svg?inline'
import SvgPic from '@/assets/icons/icon-picTicket.svg?inline'

import NoPlanning from '@/assets/images/no-planning.png'

import Treeselect from '@riophae/vue-treeselect'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'plannings-index',
  components: {
    Treeselect,
    SvgInstrumentXs,
    SvgPhone,
    SvgPic,
    IconArrow,
    IconDate,
    IconClear
  },
  data() {
    return {
      NoPlanning,

      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD' // Uses 'iso' if missing
      },
      dateRange: {
        start: '',
        end: ''
      },
      filterOptions: [
        {
          id: 'Assay Calibration',
          label: 'Assay Calibration'
        },
        { id: 'PM', label: 'PM' },
        {
          id: 'PU',
          label: 'PU'
        },
        {
          id: 'Visit',
          label: 'Visit'
        }
      ],
      filter: null,
      currentPage: 1,
      reqLoading: false
    }
  },
  computed: {
    ...mapState({
      plannings: state => state.auth.plannings
    })
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler() {
        this.getData()
      }
    },
    dateRange: {
      deep: true,
      handler(val) {
        this.setDate(val)
      }
    },
    filter: function (val) {
      this.setFilter('filter', val)
    }
  },
  mounted() {
    this.restoreFilter()
  },
  methods: {
    ...mapActions('auth', ['GET_PLANNINGS']),
    changePage(val) {
      let offset = (val - 1) * 9
      let queries = JSON.parse(JSON.stringify(this.$route.query))

      queries.offset = offset
      queries.limit = 9
      queries.t = new Date().getTime()
      this.$router.push({ query: queries })
    },
    async getData() {
      this.reqLoading = true
      let params = this.$route.query

      await this.GET_PLANNINGS(params)
      this.reqLoading = false
    },
    setFilter(ky, val) {
      let queries = JSON.parse(JSON.stringify(this.$route.query))

      queries.offset = 0
      queries.limit = 9
      queries.t = new Date().getTime()
      queries[ky] = val

      this.$router.replace({ query: queries })
    },
    setDate(val) {
      let queries = JSON.parse(JSON.stringify(this.$route.query))

      queries.offset = 0
      queries.limit = 9
      queries.t = new Date().getTime()
      queries.date_from = val.start
      queries.date_to = val.end

      this.$router.replace({ query: queries })
    },
    restoreFilter() {
      let queries = JSON.parse(JSON.stringify(this.$route.query))

      if (queries.filter) this.filter = queries.filter

      if (queries.date_from) this.dateRange.start = queries.date_from

      if (queries.date_to) this.dateRange.end = queries.date_to

      queries.t = new Date().getTime()

      this.$router.replace({ query: queries })
    },
    clearRange() {
      this.dateRange = {
        start: '',
        end: ''
      }
    },
    pageDecrease() {
      if (this.currentPage > 1) {
        this.currentPage -= 1
        this.changePage(this.currentPage)
      }
    },
    pageIncrease() {
      if (this.tickets.length == 9) {
        this.currentPage += 1
        this.changePage(this.currentPage)
      }
    },
    toTicket(val) {
      if (val && val.data) {
        let removeSpacing = val.data.number ? val.data.number.replaceAll(/\s+/g, '-') : ''
        let removeSlash = removeSpacing.replaceAll('/', '')
        this.$router.push({ name: 'ticket.detail', params: { slug: removeSlash, id: val.data.id } })
      }
    }
  }
}
</script>
